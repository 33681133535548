// import {i18n} from '@/configs'
import {history} from '@history'
import React from "react"
import moment from "moment"
import {changeLanguage} from 'i18next'
import {toast} from "react-toastify"
import {errorCode} from '@/utility/enum'
import TipText from "@components/TipText";
import {utils, writeFile} from "xlsx";

// export const {defaultLanguage = "tw"} = {}

let userMemo = {
    user: "",
    result: {},
}

/**
 * 输入框emoji
 */
export const emoji = ["😀", "😁", "😂", "😃", "😉", "😋", "😎", "😍", "😗", "🤗", "🤔", "😣", "😫", "😴", "😌", "🤓", "😛",
    "😜", "😠", "😇", "😷", "😈", "👻", "😺", "😸", "😹", "😻", "😼", "😽", "🙀", "🙈", "🙉", "🙊", "👼", "👮", "🕵", "💂",
    "👳", "🎅", "👸", "👰", "👲", "🙍", "🙇", "🚶", "🏃", "💃", "⛷", "🏂", "🏌", "🏄", "🚣", "🏊", "⛹", "🏋", "🚴", "👫",
    "💪", "👈", "👉", "👆", "🖕", "👇", "🖖", "🤘", "🖐", "👌", "👍", "👎", "✊", "👊", "👏", "🙌", "🙏", "🐵", "🐶", "🐇",
    "🐥", "🐸", "🐌", "🐛", "🐜", "🐝", "🍉", "🍄", "🍔", "🍤", "🍨", "🍪", "🎂", "🍰", "🍾", "🍷", "🍸", "🍺", "🌍", "🚑",
    "⏰", "🌙", "🌝", "🌞", "⭐", "🌟", "🌠", "🌨", "🌩", "⛄", "🔥", "🎄", "🎈", "🎉", "🎊", "🎁", "🎗", "🏀", "🏈", "🎲",
    "🔇", "🔈", "📣", "🔔", "🎵", "🎷", "💰", "🖊", "📅", "✅", "❎", "💯"]

export const isPlan = () => {
    toast.warn(global.t('计划开发中'), {
        position: toast.POSITION.TOP_CENTER
    })
}

/**
 * @param {string} date
 * @returns
 */
export function getWeek(date) { // 参数时间戳
    let week = moment(date).day() + 1
    switch (week) {
        case 1:
            return 'Mon'
        case 2:
            return 'Tue'
        case 3:
            return 'Wed'
        case 4:
            return 'Thu'
        case 5:
            return 'Fri'
        case 6:
            return 'Sat'
        default:
            return 'Sun'
    }
}

/**
 * @param {number | string} num
 * @param {number | undefined} numOfDigits
 * @returns
 */
export function toThousands(num, numOfDigits = undefined) {
    // num = (num || 0).toString().replace(/\s/g, "");
    // let number = 0,
    //     floatNum = '',
    //     intNum = '';
    // // 判断是否有小数位，有则截取小数点后的数字
    // if (num.indexOf('.') > 0) {
    //     number = num.indexOf('.'); // 获取小数点出现的位置
    //     floatNum = num.substr(number); // 截取arr.substr(form, length)
    //     intNum = num.substring(0, number); // 截取arr.substring(start, end)
    // } else {
    //     intNum = num;
    // }
    // let result = [],
    //     counter = 0;
    // intNum = intNum.split('');
    // // 利用3的倍数，向数组插入','
    // for (let i = intNum.length - 1; i >= 0; i--) {
    //     counter++;
    //
    //     // console.log(counter, targetStrCount, t )
    //     result.unshift(intNum[i]);
    //     if (!(counter % 3) && i !== 0) {
    //         result.unshift(',');
    //     }
    // }
    // return result.join('') + floatNum || '';
    // const reg = /\d{1,3}(?=(\d{3})+$)/g
    let currentNum = num
    if (numOfDigits || numOfDigits === 0) {
        currentNum = customFixed(currentNum, numOfDigits)
    }

    let numArr = `${currentNum}`.replace(/(?:(\.\d*[1-9])0+|\.0*)$/, '$1').split('.')
    let beforeDecimal = numArr.length > 0 ? numArr[0] : ''
    let afterDecimal = numArr.length > 1 ? `.${numArr[1]}` : ''

    const reg = /\d{1,3}(?=(\d{3})+$)/g
    let result = num === 0 ? num : beforeDecimal.replace(reg, '$&,')

    return `${result}${afterDecimal}`
}

/**
 * 最多保留多少位小數位（默認兩位）
 * @param val 數字，可文本格式
 * @param fixed 小數位數
 * @param showZero 小數点后显示0
 */
export function customFixed(val, fixed = 2, showZero = false) {
    const newReg = `([0-9]+\\.[0-9]{${fixed}})[0-9]*`
    let data = val ? `${val}` : "0"
    if (!showZero) data = data.replace(/(?:(\.\d*[1-9])0+|\.0*)$/, '$1')
    const value = data.replace(new RegExp(newReg), "$1")
    return fixed !== 0 ? value : value.replace(/\./g, "")
}

export function replaceAll(str, source = '', target = '') {
    return str.replace(new RegExp(source, 'gm'), target);
}

/* function getStrCount(scrstr, armstr) { //scrstr 源字符串 armstr 特殊字符
    var count = 0;
    while (scrstr.indexOf(armstr) !== -1) {
        scrstr = scrstr.replace(armstr, "")
        count++;
    }
    return count;
}*/

/**
 * @desc 函数防抖
 * @param func 目标函数
 * @param wait 延迟执行毫秒数
 * @param immediate true - 立即执行， false - 延迟执行
 */
export function debounce(func, wait, immediate) {
    let time
    let debounced = function () {
        let that = this
        if (time) clearTimeout(time)

        if (immediate) {
            let callNow = !time
            if (callNow) func.apply(that, arguments)
            time = setTimeout(
                () => {
                    time = null
                } // 见注解
                , wait)
        } else {
            time = setTimeout(
                () => {
                    func.apply(that, arguments)
                }, wait)
        }
    }

    debounced.cancel = function () {
        clearTimeout(time)
        time = null
    }
    return debounced
}

/**
 * @returns string
 */
export function getLocale() {
    // console.log(">>>>>>getLocale");
    let deflanguage = 'tw';
    let locale = localStorage.getItem('local') || deflanguage;
    if (locale !== 'en' && locale !== 'tw' && locale !== 'pt') locale = deflanguage;
    // i18n.nowLanguage = locale;
    // console.log(">>>>>>getLocale", locale);//ss
    return locale
}

/**
 *
 * @param {string} language
 */
export function setLocale(language = "tw") {
    // console.log(language)
    if (getLocale() !== language) {
        reload()
        // i18n.nowLanguage = language;
        changeLanguage(language)
        localStorage.setItem('local', language)
        // window.location.reload()

    }
}

/**
 * 退出登录时清空localStorage
 */
export function logoutClearStorage() {
    // console.log(language)
    const lang = localStorage.getItem('local') || "tw"
    const userConfig = localStorage.getItem('userConfig') || "{}"
    localStorage.clear()
    // i18n.nowLanguage = lang;
    changeLanguage(lang)
    localStorage.setItem('local', lang)
    localStorage.setItem('userConfig', userConfig)
    history.replace("/pages/login")
    // window.location.replace("/pages/login")
}

export function reload() {
    // console.log(window.location.pathname)
    history.push({
        pathname: window.location.pathname,
        search: window.location.search
    })
}

/**
 *
 * @param {Number} num
 */
export function addZero(num) {
    return num < 10 ? '0' + num : num;
}

// 获取某个时间距离某个时间有多少时分秒
export function showTime(options) {
    let {startTimeStr, endTimeStr, isAddZero, hasDays, intervalSymbol = '-'} = options;
    let startTime = startTimeStr ? new Date(startTimeStr) : new Date();
    let endTime = new Date(endTimeStr);
    if (endTime.getTime() >= startTime.getTime()) {
        let totalSecs = (endTime - startTime) / 1000; // 获得两个时间的总毫秒数. 靠前的就调换再减。
        let days = isAddZero ? addZero(Math.floor(totalSecs / 3600 / 24)) : Math.floor(totalSecs / 3600 / 24);
        let hours = isAddZero ? addZero(Math.floor((totalSecs - days * 24 * 3600) / 3600)) : Math.floor((totalSecs - days * 24 * 3600) / 3600);
        let mins = isAddZero ? addZero(Math.floor((totalSecs - days * 24 * 3600 - hours * 3600) / 60)) : Math.floor((totalSecs - days * 24 * 3600 - hours * 3600) / 60);
        let secs = isAddZero ? addZero(Math.floor(totalSecs - days * 24 * 3600 - hours * 3600 - mins * 60)) : Math.floor(totalSecs - days * 24 * 3600 - hours * 3600 - mins * 60);
        return hasDays ? (days + intervalSymbol) : '' + hours + intervalSymbol + mins + intervalSymbol + secs;
    } else {
        return null;
    }
}

/**
 * 获取错误码对应的信息
 * @param {Number|String} ec
 */
export function getErrorStr(ec) {
    return global.t(errorCode[ec.toString()]);
}

/**
 * 获取url路径参数
 * @param {string} curUrl
 * @returns {{}} urlParams
 */
export function getUrlData(curUrl) {
    let urlParams = {};
    if (curUrl.indexOf("?") !== -1) {
        const str = curUrl.split('?')[1];
        const strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
            urlParams[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
    }
    return urlParams;
}

export const isMobile = () => document.body.clientWidth <= 576

export function exportExcelFile(array, sheetName = '表1', fileName = 'example.xlsx', colSetting) {
    let jsonWorkSheet = utils.json_to_sheet(array);
    if (colSetting) jsonWorkSheet["!cols"] = colSetting;
    const workBook = {
        SheetNames: [sheetName],
        Sheets: {
            [sheetName]: jsonWorkSheet,
        }
    };
    return writeFile(workBook, fileName);
}

/**
 * 导出文件
 * @param {string} fileName
 * @param {string} content
 */
export const exportFile = (fileName, content) => {
    const eleLink = document.createElement('a');
    eleLink.download = fileName;
    eleLink.style.display = 'none';
    // 字符内容转变成blob地址
    const blob = new Blob([content]);
    eleLink.href = URL.createObjectURL(blob);
    // 触发点击
    document.body.appendChild(eleLink);
    eleLink.click();
    // 然后移除
    document.body.removeChild(eleLink);
}

export function verifyToken() {
    let token = localStorage.getItem('token');
    if (token && token !== '' && token !== 'undefined') {
        // 有token则不做处理
    } else {
        // 没有token重定向到登录界面
        window.location.href = '/pages/login';
    }
}

export const decodeUserMess = () => {
    try {
        let user = localStorage.getItem("user")
        if (user === userMemo.user) return userMemo.result
        else {
            const result = JSON.parse(unescape(user)) || {}
            userMemo = {
                user,
                result
            }
            return result
        }
    } catch (e) {
        return {}
    }
}

/**
 * 负数显示红色，正数显示绿色
 * @param {string | number} num
 * @param {string | number} rowKey
 * @param {boolean} showZero
 * @param {boolean} showSymbol  显示正负符号
 *  * @param {boolean} isPercent 显示百分号
 *  * @param {boolean} showColor 显示顔色
 *  * @param {boolean} customText 自定義
 */

/*
export const numFormat = (num, rowKey, showZero = false,
                          showSymbol = false, isPercent = false, showColor = true, customText) => {
    if (!num && !showZero) return "-"
    if (Number(num) === 0) return <span className={Number(num) === 0 && showZero && "text-secondary"}>0</span>
    return <span style={{color: showColor ? Number(num) > 0 ? "#03B615" : "#D9001B" : "initial"}}>
        {customText ||
        <TipText content={`${showSymbol && Number(num) > 0 ? "+" : ""}${toThousands(num)}${isPercent ? "%" : ""}`}
                 index={rowKey}/>}
    </span>
}
*/

/**
 * 取出两个数组的不同元素
 * @param {[]} arr1
 * @param {[]} arr2
 */
export function getArrDiff(arr1, arr2) {
    return arr1.concat(arr2).filter(function (v, i, arr) {
        return arr.indexOf(v) === arr.lastIndexOf(v);
    });
}

/**
 * 数字添加+/-前缀符号
 * @param {string | number} num
 * @param {string | number} changeType
 */
export function handleNumSymbol(num, changeType) {
    return (!num || Number(num) === 0) ? "0" : `${changeType === 1 ? "+" : "-"}${toThousands(num)}`
}

/**
 * 数字格式化为B/M/K
 * @param {string | number} num
 * @param {string | number} index
 * @param {string | number} selector
 * @param {number} fixed 保留位數
 * @param {boolean} round 保留位數用 true-toFixed进位, false-正则不进位
 */

/* export function handleRechargeNum(num, index, selector, fixed = 2, round = false) {
    let newNum = Math.abs(num)

    const renderTip = (val, index, unit) => {
        const exp = `([0-9]+\\.[0-9]{${fixed}})[0-9]*`
        return <TipText content={`${val}${unit || ""}`}
                        surfaceText={`${toThousands(
                            !round ? Number(`${val}`.replace(new RegExp(exp), "$1"))
                                : Number(val.toFixed(fixed))
                        )}${unit || ""}`}
                        selector={selector}
                        index={index}/>
    }

    if (newNum >= 1000000000) {
        newNum = num / 1000000000
        return renderTip(newNum, index, "B")
    }
    if (newNum >= 1000000) {
        newNum = num / 1000000
        return renderTip(newNum, index, "M")
    }
    if (newNum >= 1000) {
        newNum = num / 1000
        return renderTip(newNum, index, "K")
    }
    return num
}*/

/**
 * 数字添加+/-前缀符号
 * @param {string | number} num
 * @param {{
 *     index?: number | string,
 *     selector?: number | string,
 *     fixed?: number,
 *     round?: boolean,
 *     showZero?: boolean,
 *     showSymbol?: boolean,
 *     isPercent?: boolean,
 *     showColor?: boolean,
 *     format?: boolean
 * }} config {
      index: onlyText非true时必传
      selector: onlyText非true时必传
      fixed: "3-保留位數,默认三位"
      round: 保留位數用 true-toFixed进位, false-正则不进位
      showZero: 值为0时是否显示，不显示返回“-”
      showSymbol: 显示正负符号
      isPercent: 显示百分号
      showColor: 显示颜色
      showUnit: 0-万 使用单位 万, 小于0.01 万 时，显示为0
      format: "false-数字格式化为万"
  }
 * @param onlyText
 */
export function toFormatNum(num, config, onlyText = false) {
    const {
        index = 0,
        selector = "",
        fixed = 2,
        round = false,
        showZero = true,
        showSymbol = false,
        isPercent = false,
        showColor = false,
        showUnit = undefined,
        format = false,
        position = "top",
        tipStyle = {},
    } = config

    if (!num && !showZero) return "-"
    if (Number(num) === 0) return 0
    // return <span style={{color: showColor ? Number(num) > 0 ? "#03B615" : "#D9001B" : "initial"}}>
    //     {customText ||
    //     <TipText content={`${showSymbol && Number(num) > 0 ? "+" : ""}${toThousands(num)}${isPercent ? "%" : ""}`}
    //              index={rowKey}/>}
    // </span>

    const renderTip = (val, unit) => {
        const content = `${toThousands(num, 2)}${isPercent ? "%" : ""}`
        let text
        const surfaceData = toThousands(
            !round ? customFixed(val, fixed)
                : Number(val).toFixed(fixed)
        )

        if (onlyText || isPercent) {
            text = `${showSymbol && num > 0 ? "+" : ""}${surfaceData}${unit || ""}${isPercent ? "%" : ""}`
        } else {
            if (showUnit !== undefined && `${surfaceData}` === "0") {
                text = <>0</>
            } else {
                const isInt = surfaceData.indexOf(".")
                const intStr = isInt !== -1 ? surfaceData.substring(0, surfaceData.indexOf(".")) : surfaceData
                const pointStr = isInt !== -1 ? surfaceData.substring(surfaceData.indexOf(".")) : ""

                text = <>
                    {showSymbol && Number(num) > 0 ? "+" : ""}
                    <span>{intStr}</span>
                    <span style={{fontSize: "12px"}}>{pointStr}</span>
                    {unit || ""}{isPercent ? "%" : ""}
                </>
            }
        }

        return onlyText ? text :
            <span style={{color: showColor ? Number(num) < 0 ? "#22c345" : "#c32222" : ""}}>
                <TipText content={content}
                         surfaceText={text}
                         selector={selector}
                         style={tipStyle}
                         position={position}
                         index={index}/>
            </span>
    }

    const str = `${num}`.replace(/(?:(\.\d*[1-9])0+|\.0*)$/, '$1')
    let newNum = str.replace(/-/g, '')
    if (format) {
        const i = str.indexOf(".") !== -1 ? str.length - str.indexOf(".") + 1 : 0
        const handleNewNum = (n) => `${str.includes("-") ? "-" : ""}${newNum.substring(0, newNum.length - n - i + (i ? 1 : 0)) || 0}.${newNum.substring(newNum.length - n - i + (i ? 1 : 0))}`

        const handleUnit = (val) => {
            if (showUnit !== undefined) return showUnit
            if (val >= 10000) {
                return 0
            }
            return ""
        }

        const newUnit = handleUnit(newNum)
        switch (newUnit) {
            case 0:
                newNum = newNum >= 10000 ? handleNewNum(4) : 0
                return renderTip(newNum, global.t("万"))
            default:
                return renderTip(num, "")
        }
    } else {
        return renderTip(num)
    }
}

/**
 * 无小数式格式化数字（即传1111返回1111,传1000返回1K），仅支持整数
 * @param {string | number} num
 */
export function toFormatNumUnFixed(num) {
    if (!num || Number(num) === 0) return "0"
    const str = `${num}`.replace(/(?:(\.\d*[1-9])0+|\.0*)$/, '$1')
    let newNum = str.replace(/-/g, '')
    const handleNewNum = (n) => `${str.substring(0, str.length - n)}.${str.substring(str.length - n)}`
    const handleNum = (format, old, oldUnit) => {
        const val = customFixed(handleNewNum(format))
        if (val.indexOf(".") === -1) {
            if (oldUnit === "M") return `${val}B`
            return handleNum(format + 3, val, oldUnit === "" ? "K" : "M")
        }
        return `${old}${oldUnit}`
    }

    return handleNum(3, newNum, "")
}

export function validDate(time) {
    return !isNaN(Date.parse(time)) && isNaN(time)
}

export function customChartTip({dataPointIndex, w}, content, otherConfig = {}) {
    const {frontSeries, frontColors, extraSeries, extraColors, extraCol, customColor} = otherConfig
    const {globals: {categoryLabels, colors, collapsedSeriesIndices}, config} = w
    const {xaxis: {categories}} = config
    const category = categoryLabels.length > 0 ? categoryLabels : categories
    let newSeries = config.series.filter((_, index) => !collapsedSeriesIndices.includes(index))
    let newColors = colors.filter((_, index) => !collapsedSeriesIndices.includes(index))
    if (frontSeries) newSeries = [...frontSeries, ...newSeries]
    if (extraSeries) newSeries = [...newSeries, ...extraSeries]
    if (frontColors !== undefined) newColors = [frontColors].concat(newColors)
    if (extraColors) newColors.push(extraColors)
    const arr = newSeries.map((_, i) => newSeries[i].data[dataPointIndex])
    arr.splice(0, 1)
    return '<div class="apexcharts-tooltip-title">' +
        '<span>' + category[dataPointIndex] + '</span>' +
        '</div>' +
        newSeries.reduce((pre, next, index) =>
                pre +
                `<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: block;">
                                <div class="apexcharts-tooltip-text d-flex justify-content-between align-items-center"
                                     style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                    <div class="apexcharts-tooltip-y-group d-flex align-items-center" style="min-width: 120px">
                                        <div class="apexcharts-tooltip-marker" style="background-color: ${customColor ?
                    customColor(next, dataPointIndex) : newColors[index]}"></div>
                                        <span class="apexcharts-tooltip-text-y-label">${next.name}: </span>
                                    </div>
                                     <div class="apexcharts-tooltip-text-y-value text-right" style="min-width: 90px">
                                        ${content(next, dataPointIndex)}
                                     </div>
                                     <div class="apexcharts-tooltip-text-y-value text-right ${extraCol ? '' : 'd-none'}"
                                     style="min-width: 90px">
                                        ${extraCol && extraCol(next, dataPointIndex, newSeries, index, arr)}
                                     </div>
                                </div></div>`
            , "")
}

export function chartYFormat(val) {
    val = Number(val).toFixed(0);
    if (val.toString().length > 6 && val.toString().length <= 9) {
        let new_num = (val * Math.pow(0.1, 6));
        return new_num.toFixed(0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + "M";
    } else if (val.toString().length > 9) {
        let new_num = val * Math.pow(0.1, 9);
        return new_num.toFixed(0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,") + "B";
    } else {
        return val.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")
    }
}

// 统计图计算率
export function handlePercent(opts, fixed = 2) {
    const {seriesPercent} = opts.w.globals
    const val = seriesPercent[opts.seriesIndex]
    let rate = 100
    if (opts.seriesIndex === (seriesPercent.length - 1)) {
        seriesPercent.forEach((item, index) => {
            if (index !== opts.seriesIndex) rate = rate - Number(item).toFixed(fixed)
        })
    } else {
        rate = val
    }
    return Number(Number(rate).toFixed(fixed))
}

// 计算率
export function customPercent(sum, arr, index) {
    if (arr[index] === 0) return 0

    // 初始值随意
    let singleVal = 0.1
    let dataObj = {}

    arr.forEach(ele => {
        if (dataObj[ele]) {
            dataObj[ele]++
        } else {
            dataObj[ele] = 1
        }
    })

    for (const key in dataObj) {
        if (key !== "0" && dataObj[key] === 1) {
            singleVal = key
            break;
        }
    }

    let percent = 100
    const val = arr[index]

    if (sum !== 0) {
        if (`${singleVal}` !== `${val}` || (singleVal === 0.1 && index !== arr.length - 1)) {
            percent = customFixed(val / sum * 100)
        } else {
            percent = 10000

            arr.forEach((item, i) => {
                if (`${singleVal}` !== `${item}` || (singleVal === 0.1 && i !== index))
                    percent -= customFixed(item / sum * 100) * 100
            })
            percent = (percent / 100).toFixed(2)
        }
    }
    return `${Number(percent)}%`
}

/**
 * 玩家UID帶* 或 ** 或不帶
 * @param {string} playerID 玩家UID
 * @param {string} playerName 玩家名稱
 * @param {number} isTest 0=>正常,1=>测试,2=>测试*
 * @param {number} mode 0=>单行显示,1=>换行显示,换行时暂不支持tip
 * @param {boolean} showTip 开启提示，默认开启
 * @param {string | number} index showTip為true時必传
 * @param {boolean} isPlayer 是否玩家
 * @returns  * / **
 */
export function handleTestPlayer(playerID, playerName, isTest = 0, mode = 0, showTip = true, index, isPlayer = false) {
    if (!playerID) return "-"
    if (mode === 0) {
        if (isTest === 0) {
            const playerText = `${playerName}(${playerID})`
            return showTip ? <TipText index={`${index}_PARENT`}
                                      content={playerText}
            /> : playerText
        } else {
            const surfaceText = `(${playerID}${isTest === 1 ? "*" : "**"})`
            const testText = <>{playerName}<b>{surfaceText}</b></>
            return showTip ? <TipText index={`${index}_PARENT`}
                                      content={`${playerName}${surfaceText}`}
                                      surfaceText={testText}
            /> : testText
        }
    } else {
        return <div className={isMobile() ? "d-flex align-items-center" : ""}>
            <div className="text-center">{playerName}</div>
            <div className="text-center">
                ({isPlayer ? <a
                onClick={e => {
                    e.preventDefault()
                    history.push(`/player/PlayerDetails?playerID=${playerID}`)
                }}
                href="/#">{playerID}</a> : playerID})<b>{new Array(isTest).join("*")}</b>
            </div>
        </div>
    }
}

export function subString(a, b) {
    if (a === b) return 0
    // if (a.length < b.length) return subString(b, a)
    // 将字符串a和b补全成同等长度
    while (a.length < b.length) {
        a = '0' + a;
    }
    while (b.length < a.length) {
        b = '0' + b;
    }
    if (a < b) return subString(b, a)
    // res保存结果，c用来标识有无借位的情况
    let res = '', c = 0;
    a = a.split('');
    b = b.split('');
    while (a.length) {
        const num1 = ~~a.pop();
        const num2 = ~~b.pop();
        if ((num1 - c) >= num2) {
            c = num1 - num2 - c;
            res = c + res;
            c = false;
        } else {
            c = num1 + 10 - num2 - c;
            res = c + res;
            c = true
        }
        // 判断最高位有无借位，若有借位，则说明结果为负数
        // if (a.length === 0 && c) {
        //     res = '-' + res
        // }

    }
    return res.replace(/^0?/, '');
}

export function add(a, b) {
    let res = '', c = 0;
    a = a.split('');
    b = b.split('');
    while (a.length || b.length || c) {
        c += ~~a.pop() + ~~b.pop();
        res = c % 10 + res;
        // console.log(res);
        c = c > 9;
    }
    return res.replace(/^0?/, '');
}

export function doPlus(num1, num2) {
    const a = `${num1}`
    const b = `${num2}`
    const getAbs = (v) => v.replace(/[^\d.]+/g, "")
    let val1 = getAbs(a), val2 = getAbs(b)
    let len1 = 0, len2 = 0
    if (a.indexOf(".") !== -1) {
        len1 = a.substring(a.indexOf(".")).length - 1
    }
    if (b.indexOf(".") !== -1) {
        len2 = b.substring(b.indexOf(".")).length - 1
    }
    const len = Math.max(len1, len2)
    val1 = val1.replace(/\D/g, "") + new Array(len - len1 + 1).join("0")
    val2 = val2.replace(/\D/g, "") + new Array(len - len2 + 1).join("0")
    let val
    let symbol = ""

    if (a < 0 && b < 0) {
        val = add(val1, val2)
        symbol = "-"
    } else if (a < 0 || b < 0) {
        val = subString(val1, val2)
        if ((a < 0 && Number(val1) > Number(val2)) || (b < 0 && Number(val2) > Number(val1))) symbol = "-"
    } else {
        val = add(val1, val2)
    }

    if (val === '' || val === 0) {
        return 0
    }
    if (len > 0) {
        const beforeVal = replaceAll(val.slice(0, val.length - len) || "0", "^0*", "")
        val = `${beforeVal || 0}.${val.slice(-len)}`.replace(/(?:(\.\d*[1-9])0+|\.0*)$/, '$1')
    }

    if (val > 1) {
        val = `${val}`.replace(/^0+/, '')
    } else if (val.includes("00.")) {
        val = Number(val)
    }

    return `${symbol}${val}`
}

export function doMinus(num1, num2) {
    const a = `${num1}`
    const b = `${num2}`
    const getAbs = (v) => v.replace(/[^\d.]+/g, "")
    let val1 = getAbs(a), val2 = getAbs(b)
    let len1 = 0, len2 = 0
    if (a.indexOf(".") !== -1) {
        len1 = a.substring(a.indexOf(".")).length - 1
    }
    if (b.indexOf(".") !== -1) {
        len2 = b.substring(b.indexOf(".")).length - 1
    }
    const len = Math.max(len1, len2)
    val1 = val1.replace(/\D/g, "") + new Array(len - len1 + 1).join("0")
    val2 = val2.replace(/\D/g, "") + new Array(len - len2 + 1).join("0")

    let val
    let symbol = ""

    if (a <= 0 && b <= 0) {
        val = subString(val1, val2)
        symbol = Number(val1) > Number(val2) ? "-" : ""
    } else if (a > 0 && b > 0) {
        val = subString(val1, val2)
        symbol = Number(val2) > Number(val1) ? "-" : ""
    } else {
        // 一个为负一个正或都为0
        if (a < 0 || (Number(val1) === 0 && Number(val2) !== 0)) {
            val = add(val1, val2)
            symbol = "-"
        } else {
            val = add(val1, val2)
        }
    }
    if (val === '' || val === 0) {
        return 0
    }

    if (len > 0) {
        const beforeVal = replaceAll(val.slice(0, val.length - len) || "0", "^0*", "")
        val = `${beforeVal || 0}.${val.slice(-len)}`.replace(/(?:(\.\d*[1-9])0+|\.0*)$/, '$1')
    }

    if (val > 1) {
        val = `${val}`.replace(/^0+/, '')
    } else if (val.includes("00.")) {
        val = Number(val)
    }

    return `${symbol}${val}`
}

/**
 * @param {*} arg1 数字
 * @param {*} arg2 数字
 * @description 两数字相乘 保持精度
 */
export const doMul = (arg1, arg2) => {
    let m = 0
    const s1 = arg1.toString()
    const s2 = arg2.toString()
    try {
        m += s1.split('.')[1].length
    } catch (e) {
        // console.error("错误")
    }
    try {
        m += s2.split('.')[1].length
    } catch (e) {
        // console.error("错误")
    }
    return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)
}

/**
 * @param {*} arg1 数字
 * @param {*} arg2 数字
 * @description 两数字相除 保持精度
 */
export const doDiv = (arg1, arg2) => {
    let t1 = 0
    let t2 = 0
    let r1, r2
    try {
        t1 = arg1.toString().split('.')[1].length
    } catch (e) {
        // console.error("错误")
    }
    try {
        t2 = arg2.toString().split('.')[1].length
    } catch (e) {
        // console.error("错误")
    }
    r1 = Number(arg1.toString().replace('.', ''))
    r2 = Number(arg2.toString().replace('.', ''))
    let intDiv = r1 / r2
    let pow = Math.pow(10, t2 - t1)
    return doMul(intDiv, pow) // 这里用上面定义好的乘法运算
}

export const getTextLen = (val, useByte = false) => {
    if (!val) return 0

    if (useByte) {
        let len = 0;
        for (let i = 0; i < val.length; i++) {
            if (val.charCodeAt(i) > 127 || val.charCodeAt(i) === 94) {
                len += 2;
            } else {
                len++;
            }
        }
        return len;
    } else {
        return val.length
    }
}

export const handleAmount = (amount, active) => {
    const params = amount.replace(/,/g, "")
    if (!params || Number(params) === 0) return "0"
    switch (active) {
        case "K":
            return `${params}000`
        case "M":
            return `${params}000000`
        case "B":
            return `${params}000000000`
        default:
            return params
    }
}

// 将rgb颜色转成hex
export function colorRGB2Hex(color) {
    const rgb = color.split(',');
    const r = parseInt(rgb[0].split('(')[1]);
    const g = parseInt(rgb[1]);
    const b = parseInt(rgb[2].split(')')[0]);
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
}

// 将hex颜色转成rgb
export function hexToRgba(hex, opacity) {
    const RGBA = "rgba(" + parseInt("0x" + hex.slice(1, 3)) + "," + parseInt("0x" + hex.slice(3, 5)) + "," + parseInt("0x" + hex.slice(5, 7)) + "," + opacity + ")";
    return {
        red: parseInt("0x" + hex.slice(1, 3)),
        green: parseInt("0x" + hex.slice(3, 5)),
        blue: parseInt("0x" + hex.slice(5, 7)),
        rgba: RGBA
    }
}

export function saveUserConfig(configKey, config) {
    if (configKey) {
        const data = localStorage.getItem("userConfig")
        let userConfig = data ? JSON.parse(localStorage.getItem("userConfig")) : {}
        userConfig = {
            ...userConfig,
            [configKey]: {
                ...userConfig[configKey],
                ...config
            }
        }
        localStorage.setItem("userConfig", JSON.stringify(userConfig))
    }
}

export function saveUrlParams(urlKey, params) {
    if (urlKey) {
        const data = localStorage.getItem("urlParams")
        let urlParams = data ? JSON.parse(localStorage.getItem("urlParams")) : {}
        if (params) {
            urlParams = {
                ...urlParams,
                [urlKey]: params
            }
        } else {
            urlParams[urlKey] = undefined
        }
        localStorage.setItem("urlParams", JSON.stringify(urlParams))
    }
}

export function getUserConfig(configKey) {
    if (configKey) {
        const data = localStorage.getItem("userConfig")
        let userConfig = data ? JSON.parse(localStorage.getItem("userConfig")) : {}
        return userConfig[configKey]
    }
    return {}
}

export function getUrlParams(urlKey) {
    if (urlKey) {
        const data = localStorage.getItem("urlParams")
        let urlParams = data ? JSON.parse(localStorage.getItem("urlParams")) : {}
        return urlParams[urlKey]
    }
    return {}
}

export const isSafari = navigator.userAgent.indexOf("Safari") > 0 && navigator.userAgent.indexOf("Chrome") < 0;

/**
 * 提交时清除未改动的数据
 * @param {object} init
 * @param {object} obj
 * @param {string} key 唯一键值，一般为id,多个逗号隔开
 */
export function clearData(init, obj, key) {
    let data = {}
    for (let i in obj) {
        if (obj[i] !== undefined) {
            if (Array.isArray(obj[i])) {
                if (init[i] && Array.isArray(init[i]) && JSON.stringify(obj[i].sort()) !== JSON.stringify(init[i].sort())) {
                    data[i] = obj[i]
                }
            } else {
                if (init[i] !== obj[i]) {
                    data[i] = obj[i]
                }
            }
        }
    }
    if (key) {
        key.split(",").forEach(item => data[item] = init[item])
    }
    return data
}

// 获取上传文件类型
export function getFileMimeType(file, fileType = ['jpg', 'png']) {
    const map = {
        'FFD8FF': 'jpg',
        // '89504E47': 'png',
        '89504E': 'png',
        // '47494638': 'gif',
        // "52494646": 'webp'
        // "5B75726C": 'csv'
        "5B7572": 'csv'
    }

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    return new Promise((resolve) => {
        reader.onload = (event) => {
            try {
                let array = new Uint8Array(event.target.result);
                array = array.slice(0, 3);
                let arr = [...array]
                let key = arr.map(item => item.toString(16)
                    .toUpperCase()
                    .padStart(2, '0'))
                    .join('')
                resolve(!!map[key] && fileType.includes(map[key]))
            } catch (e) {
                resolve(false);
            }
        };
    });
}

export const getPermission = (link) => {
    try {
        const nav_links = JSON.parse(localStorage.getItem('nav_links'))
        return nav_links[link]
    } catch {
        return false
    }
}

const hexArr = ['4', '2', '3', '4', '8', '5', '1', '4', '9', '5', '2', '3', '8', '5', '6', '1', '4', '6', '7', '3', '9', '8', '4', '8', '7', '9', '2', '4', '7', '3', '1', '5']

export function encodeHex(t) {
    const othStr = hexArr[parseInt(`${t}`.slice(-2)) % 31]
    const a = parseInt(othStr + `${t}`.split("").reverse().join("") + othStr)
    return a.toString(16)
}

export function decodeHex(str) {
    const deStr = parseInt(str, 16)
    // 获取原数字
    return deStr.slice(1, deStr.length - 1).split("").reverse().join("");
}

const obj = {
    "0": "B",
    "1": "2",
    "2": "L",
    "3": "4",
    "4": "8",
    "5": "A",
    "6": "E",
    "7": "4",
    "8": "E",
    "9": "5",
    "10": "B",
    "11": "F",
    "12": "8",
    "13": "5",
    "14": "3",
    "15": "E",
    "16": "4",
    "17": "6",
    "18": "7",
    "19": "3",
    "20": "9",
    "21": "8",
    "22": "4",
    "23": "M",
    "24": "B",
    "25": "9",
    "26": "G",
    "27": "F",
    "28": "E",
    "29": "3",
    "30": "1",
    "31": "A",
    "32": "5",
    "33": "2",
    "34": "C",
    "35": "1",
    "36": "B",
    "37": "N",
    "38": "8",
    "39": "3",
    "40": "9",
    "41": "4",
    "42": "K",
    "43": "6",
    "44": "D",
    "45": "E",
    "46": "3",
    "47": "G",
    "48": "B",
    "49": "K",
    "50": "3",
    "51": "D",
    "52": "F",
    "53": "3",
    "54": "J",
    "55": "G",
    "56": "A",
    "57": "J",
    "58": "G",
    "59": "K",
    "60": "4",
    "61": "A",
    "62": "N",
    "63": "1",
    "64": "C",
    "65": "J",
    "66": "C",
    "67": "J",
    "68": "5",
    "69": "F",
    "70": "3",
    "71": "8",
    "72": "J",
    "73": "G",
    "74": "F",
    "75": "K",
    "76": "G",
    "77": "J",
    "78": "5",
    "79": "C",
    "80": "A",
    "81": "E",
    "82": "6",
    "83": "L",
    "84": "K",
    "85": "1",
    "86": "M",
    "87": "N",
    "88": "B",
    "89": "2",
    "90": "5",
    "91": "F",
    "92": "G",
    "93": "4",
    "94": "G",
    "95": "2",
    "96": "G",
    "97": "L",
    "98": "C",
    "99": "H"
}

// 房间ID转进制
export function encode(t) {
    if (t) {
        const data = 10000 + parseInt(t)
        // 进制转换
        const str = data.toString(16);
        // 根据末二位数字添加额外前后缀
        const othStr = obj[parseInt(`${t}`.slice(-2))]
        // 额外前缀 +【转换后字符】（逆序） + 额外后缀
        const a = othStr + str.split("").reverse().join("") + othStr
        return replaceAll(parseInt(a, 32).toString(24).toUpperCase(), "I", "i");
    }
    return t
}

// 获取原房间ID
export function decode(str) {
    const deStr = parseInt(str.toLowerCase(), 24).toString(32)
    const data = deStr.slice(1, deStr.length - 1).split("").reverse().join("")
    // 获取原数字
    return parseInt(data, 16) - 10000;
}

// 搜索参数字符串转数组
export function strToArr(params, strFilter = []) {
    if (!params) return undefined
    const arr = `${params}`.split(",").flatMap(item => strFilter.includes(item) ? [] : item)
    return arr.length > 0 ? arr : undefined
}

// 获取近七天
export const getSevDay = (i, beforeNow = 0) => {
    const obj = {
        0: 6,
        1: 14,
        2: 29,
    }

    let start = moment().subtract(obj[i] + beforeNow, 'd').format("YYYY-MM-DD HH:mm:ss");
    // 周日日期
    let end = moment().subtract(beforeNow, 'd').format("YYYY-MM-DD HH:mm:ss");
    return [start, end]
}
export const getWeekDay = (i) => {
    // 计算今天是这周第几天
    let weekOfDay = parseInt(moment().format('E'));
    // 周一日期
    let monday = moment().subtract(weekOfDay + 7 * i - 1, 'days').format('YYYY-MM-DD 00:00:00');
    // 周日日期
    let sunday = moment().subtract(weekOfDay + 7 * (i - 1), 'days').format('YYYY-MM-DD 23:59:59');
    return [monday, sunday]
}

export const getMonthDay = (i) => {
    return [moment(new Date()).subtract(i, 'months').startOf('month').format('YYYY-MM-DD 00:00:00'), moment(new Date()).subtract(i, 'months').endOf('month').format('YYYY-MM-DD 23:59:59')]
}

export const handleName = (val) => {
    if (val.includes("Province of China")) {
        return <>
            <span style={{
                visibility: "hidden",
                maxWidth: 0,
                maxHeight: 0,
                display: "inline-block",
                overflow: "hidden"
            }}>{val}</span>
            <span>{val.replace("(Province of China)", "")}</span>
        </>
    }
    return <span>{val || "-"}</span>
}

// 创建 context 并暴露Consumer和Provider模式
let {Consumer, Provider} = React.createContext();

// eslint-disable-next-line
export default {
    clearData,
    getWeek,
    toThousands,
    replaceAll,
    debounce,
    showTime,
    addZero,
    getErrorStr,
    isMobile,
    toFormatNum,
    // numFormat,
    Consumer,
    Provider
}
